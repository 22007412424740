import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "glushkov" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Glushkov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Атакующий игрок, действует преимущественно на левом фланге. Обладает хорошим дриблингом, умеет набрать скорость с мячом. Всегда ищет обострение ситуации.</p>
          <p>Командный тип, уверенно взаимодействует с партнерами во время атакующих действий. Умеет открываться и двигаться без мяча.</p>
          <p>Помогает команде при обороне, пытаясь начинать отбор мяча на половине поля соперника и в середине. Уверенно исполняет стандартные положения.</p>
          <p>Обладает стабильным пасом, хорошо действует одним касанием. Эффективная концовка в голевых эпизодах. </p>
          <p>Осенью 2018-го в 8 матчах за вторую команду «Урала» забил 7 мячей.</p>
          <p>Воспитанник московского «Динамо».</p>
        </div>
      </div>
    )
  }
}