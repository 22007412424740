import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Img from "gatsby-image"

export default class Player extends React.Component {
  fullName() {
    const { player } = this.props.data
    return `${player.first_name_ru} ${player.last_name_ru}`
  }

  renderPlayerInfo() {
    const { player } = this.props.data;
    return (
      <div className="col-lg-3 col-md-6">
        <div className="info">
          <div className="position-map">
            <div className={ `position primary ${player.primary_position}` } />
            { player.secondary_position && <div className={ `position secondary ${player.secondary_position}` } /> }
          </div>
          <div className="info-item">
            <div className="info-item-name">Клуб</div>
            <div className="info-item-value">{player.club_ru}</div>
          </div>
          <div className="info-item">
            <div className="info-item-name">Соревнование</div>
            <div className="info-item-value">{player.competition_ru}</div>
          </div>
          <div className="info-item">
            <div className="info-item-name">Гражданство</div>
            <div className="info-item-value">{player.nationality_ru}</div>
          </div>
          <div className="info-item">
            <div className="info-item-name">Возраст</div>
            <div className="info-item-value">{player.birth_date}</div>
          </div>
          <div className="info-item">
            <div className="info-item-name">Нога</div>
            <div className="info-item-value">{player.foot_ru}</div>
          </div>
          <div className="info-item">
            <div className="info-item-name">Рост</div>
            <div className="info-item-value">{player.height} см</div>
          </div>
        </div>
      </div>
    )
  }

  renderBasicInfo() {
    const { player } = this.props.data
    return (
      <div className="col-lg-3 col-md-6">
        <div className="photo">
          <Img fluid={player.image.childImageSharp.fluid} alt={this.fullName()} width="100%" />
          <img src={player.club_logo.childImageSharp.fixed.src} className="club-logo" alt={this.club} />
          { player.nationality_flag && <img src={player.nationality_flag.publicURL} className="country-flag" alt={this.nationality} /> }
        </div>
      </div>
    )
  }

  renderStat(stat) {
    const key = stat.key
    const player = this.props.data.player
    if (!player[key]) return null
    return (<div className="stat">
      <div className="stat-name">{ stat.value_ru } { stat.average && <sup>90</sup>}</div>
      <div className="stat-value">{ player[key] }</div>
    </div>);
  }

  renderStats() {
    return (
      <div className="col-lg-6 col-md-12">
        <div className="stats">
          { this.props.data.stats.nodes.map(stat => this.renderStat(stat)) }
        </div>
        <div className="stats-credits">
          <p>
            <sup>90</sup> — в среднем за 90 минут
          </p>
        </div>
      </div>
    )
  }

  renderDescription() {
    return null
  }

  render() {
    const { player } = this.props.data
    return (
      <Layout lang="ru">
        <Helmet>
          <title>{player.first_name_ru} {player.last_name_ru} — Catenaccio</title>
        </Helmet>
        <section className="player-section">
          <h1 className="player-name">
            {player.first_name_ru} {player.last_name_ru}
          </h1>
          <div className="container">
            <div className="row">
              {this.renderBasicInfo()}
              {this.renderPlayerInfo()}
              {this.renderStats()}
            </div>
          </div>
        </section>

        <article>
          <section className="container text-section">{this.renderDescription()}</section>
        </article>
      </Layout>
    )
  }
}

export const query = graphql`
fragment PlayerInfo on PlayersYaml {
  image {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
  first_name
  first_name_ru
  last_name
  last_name_ru
  primary_position
  secondary_position
  club
  club_ru
  club_logo {
    childImageSharp {
      fixed(width: 120) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  competition
  competition_ru
  nationality
  nationality_ru
  nationality_flag {
    publicURL
  }
  foot
  foot_ru
  birth_date
  height
  matches
  minutes_avg
  shots_avg
  shots_on_goal
  goals_avg
  goal_conversion
  touch_in_box_avg
  received_long_pass_avg
  pass_accuracy
  key_passes_avg
  crosses_avg
  dribbles_success
  duels_success
}
`